<template>
  <v-app class="applic">
    <v-main>
      <CookieBanner />
      <router-view ref="routerv"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import CookieBanner from './components/CookieBanner.vue';
export default {
  name: 'App',
  methods: {
    scrollToElement(elem) {
      console.log(this.$refs, this.$refs.routerv)
      this.$refs.routerv.scrollToRef(elem)
    }
  }, components: {
    CookieBanner,
  },
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  min-height: 100vh !important;
}

.applic {
  background-color: #FFFAF5;
  width: 100vw;
  min-height: 100vh;
  /* Ensure the container is at least the full height of the viewport */
  overflow: auto;
  /* Allow scrolling if content overflows */
}
</style>
