<template>
<div>
  <HomeViewDesktop v-if="currBP !== 'xs'"/>
  <HomeViewMobile v-else/>
</div>
</template>

<script>
import HomeViewMobile from "./HomeViewMobile.vue";
import HomeViewDesktop from "./HomeViewDesktop.vue";

export default {
  name: "BombshelterSchool",
  components: { HomeViewMobile, HomeViewDesktop },
  computed: {
    currBP() {
      if (this.$vuetify.display['sm']) {
        return 'sm';
      } else if (this.$vuetify.display['xs']) {
        return 'xs';
      } else if (this.$vuetify.display['md']) {
        return 'md';
      } else if (this.$vuetify.display['lg']) {
        return 'lg';
      } else {
        return 'def';
      }
    }
  },
};
</script>

<style scoped>
.main-cont {
  width: 100vw;
  overflow: hidden !important;
}

.width-100-percent {
  font-size: 100% !important;
}

.mazok {
  background-image: url("../assets/2/bg-mazok.svg") !important;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: left;
  padding: 30px;
  height: 100%;
  border: none;
}

.appbar {
  z-index: 9999;
  overflow: visible !important;
}

.v-toolbar__content {
  overflow: visible !important;
}

.appbar h1 {
  font-family: "KyivType Sans";
  font-size: 25px;
  font-weight: 700;
  line-height: 38.5px;
  text-align: left;
  color: white;
}

.appbar h2 {
  font-family: "KyivType Sans";
  font-size: 17px;
  font-weight: 900;
  line-height: 24px;
  text-align: left;
  color: white;
}

.v-container {
  max-width: 1400px;
}

.child {
  position: absolute;
  top: 100px;
}

.child-1 {
  left: 0;
  height: 100%;
  width: 100%;
}

.moving-image {
  position: absolute;
  left: -15%;
  animation: moveImage 50s linear infinite;
}

@keyframes moveImage {
  0% {
    left: -15%;
  }

  50% {
    left: -50%;
  }

  100% {
    left: -15%;
  }
}

.rotating-image {
  display: block;
  margin: 0 auto;
  animation: rotateImage 1s infinite alternate;
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(5deg);
    /* Rotate 5 degrees clockwise */
  }

  100% {
    transform: rotate(0deg);
  }
}

.parent {
  position: relative;
  overflow: visible;
}

@font-face {
  font-family: "KyivType Sans";
  font-weight: 500;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url("../assets/fonts/KyivTypeSans/KyivTypeSans-Medium.ttf");
}

@font-face {
  font-family: "KyivType Sans";
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url("../assets/fonts/KyivTypeSans/KyivTypeSans-Bold.ttf");
}

@font-face {
  font-family: "KyivType Sans";
  font-weight: 800;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: url("../assets/fonts/KyivTypeSans/KyivTypeSans-Heavy.ttf");
}

.logo {
  cursor: pointer;
  height: 50px;
}

.plus-sign {
  height: 20px;
}

.bus-container {
  position: relative;
  width: 100vw !important;
  min-width: 900px !important;
  max-width: 1766px !important;
}

@media (max-width: 900px) {
  .appbar {
    z-index: 9999;
  }

  .appbar h1 {
    font-family: "KyivType Sans";
    font-size: 18px !important;
    font-weight: 700 !important;
    line-height: 22px !important;
    text-align: left;
    color: white;
  }

  .appbar h2 {
    font-family: "KyivType Sans";
    font-size: 12px !important;
    font-weight: 900 !important;
    line-height: 18px !important;
    text-align: left;
    color: white;
  }

  h6 {
    font-size: 14px !important;
    line-height: 22px !important;
  }

  h1 {
    font-size: 30px !important;
    font-weight: 800;
    line-height: 45px !important;
  }

  .h1-special {
    line-height: 20px !important;
  }

  h2 {
    font-size: 18px !important;
    line-height: 20px !important;
  }

  h3 {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  h4 {
    font-size: 18px !important;
    line-height: 24px !important;
  }

  h5 {
    font-size: 14px !important;
    line-height: 18px !important;
  }
}

.bus {
  width: 100%;
  height: auto;
}

.person {
  position: absolute;
  align-content: end;
  height: 200px;
  width: 150px;
}

.person-img {
  cursor: pointer;
  height: 200px;
  max-width: 100%;
  align-self: center;
}

.tooltip-amp {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(80px, -75px);
  z-index: 9998;
  width: 250px;
  height: 155px;
}

.tooltip-amp-mobile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 0%;
  z-index: 9;
  width: 240px;
  height: 155px;
}

.tooltip-amp-child {
  position: absolute;
  z-index: 9990;
}

.bg-img {
  width: 250px;
  /* Adjust width as needed */
  height: auto;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  top: 0%;
  left: 0%;
  padding: 15px 25px 15px 25px;
}

.tooltip-content h1 {
  font-family: "KyivType Sans";
  font-size: 16px !important;
  font-weight: 700;
  line-height: 22px !important;
  text-align: left;
  color: #1743e1;
}

.tooltip-content h2 {
  font-family: "KyivType Sans";
  font-size: 10px !important;
  font-weight: 800;
  line-height: 11px !important;
  text-align: right;
  color: #1743e1;
}

p {
  font-family: "KyivType Sans";
  font-size: 10px;
  font-weight: 800;
  line-height: 11px;
  color: #1743e1;
}

h1 {
  font-family: "KyivType Sans";
  font-size: 40px !important;
  font-weight: 800 !important;
  line-height: 60px !important;
  text-align: center;
  color: white;
}

.h1-special {
  color: #ffea7a;
  text-align: left;
  font-family: "KyivType Sans";
  font-size: 30px;
  font-weight: 800;
  line-height: 60px;
}

.h1-huge {
  font-family: KyivType Sans;
  font-size: 56px;
  font-weight: 700;
  line-height: 64.8px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #3232f9;
}

h2 {
  font-family: "Helvetica Neue";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #3232f9;
  text-transform: uppercase;
}

h3 {
  font-family: "Helvetica Neue";
  font-size: 16px;
  font-weight: 400;
  line-height: 24.8px;
  text-align: left;
  color: #3232f9;
}

h4 {
  font-family: "KyivType Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #3232f9;
}

h5 {
  font-family: "KyivType Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #3232f9;
}

h6 {
  font-family: Helvetica Neue;
  font-size: 24px;
  font-weight: 700;
  line-height: 37.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.v-expansion-panel-title__icon {
  width: 24px;
  height: 24px;
}

.img-expansion {
  display: block;
  width: 24px;
}

.wrapper {
  position: relative;
}

h5 {
  font-family: "KyivType Sans";
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.mt-250 {
  margin-top: 250px !important;
}

.mt-200 {
  margin-top: 200px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.slider-amp {
  width: 100vw;
  margin: auto;
  padding: auto;
  background-color: transparent;
  height: 400px !important;
}

.img-next,
.img-prev {
  display: block;
  width: 12px;
  z-index: 9997;
}

.donate-btn {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.absol {
  position: fixed !important;
  bottom: 10px;
  right: 0px;
}

.person-name {
  font-family: KyivType Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1743e1;
}

.person-btn {
  font-family: "KyivType Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #1743e1;
  width: 100%;
}

.become-amp {
  font-family: "KyivType Sans";
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
  color: #ffea7a;
}

.become-amp-txt {
  font-family: "KyivType Sans";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
  color: #ffffff;
}

/* this query will apply for the devices those have width smaller than 480px */
@media (max-width: 480px) {
  .vert_video {
    width: 100%;
    height: 100%;
  }
}

.vert_video {
  width: 75%;
  height: 75%;
}
</style>
